/* theme/hero */
.hero {
  @apply relative flex flex-col overflow-x-clip;

  &:global(.has-bg-color) {
    &:global(.light-summer-green) {
      @apply bg-summer-green-23;
    }

    &:global(.summer-green) {
      @apply bg-summer-green-50;
    }
  }

  &:global(.no-breadcrumbs) {
    & :global(.content) {
      @apply pt-0;
    }
  }

  &:global(.is-centered) {
    & :global(.content) {
      @apply text-center mx-auto;
    }
  }

  &:global(.size-small) {
    min-height: 420px;
  }

  &:global(.size-large) {
    @apply min-h-[700px];

    &:global(.has-articles) {
      @apply min-h-[800px];
    }
  }

  &:global(.size-giant) {
    @apply min-h-[100vh];

    &:global(.has-articles) {
      @apply min-h-[calc(100vh-100px)];
    }
  }

  &:global(.has-image) {
    @apply text-white;
  }

  & :global(.inner-wrapper) {
    @apply box-content px-4 md:px-8;
    @apply max-w-8xl mx-auto;
    @apply w-[calc(100%-2rem)];
    @apply flex-grow-[1];

    @apply flex flex-col justify-center; /* position content */

    /* Only a bit of extra spacing due to Panel heading */
    &:global(.extra-padding) {
      @apply pb-8;
    }
  }

  & :global(.breadcrumbs) {
    @apply w-[calc(100%-2rem)] pt-[70px] sm:pt-[100px] md:pt-[100px] lg:pt-[140px] mb-0 z-[2] h-[20px];
  }

  & :global(.content) {
    @apply max-w-1xl z-10;
    @apply py-10; /* standard padding to breadcrumb/bottom */

    &:global(.campaign) {
      & h1 {
        @apply text-4.5xl lg:text-6xl;
      }
      & :global(div[data-block="core/paragraph"]) {
        @apply text-lg lg:text-1.5xl;
      }
    }
  }

  & :global(.background) {
    @apply absolute top-0 left-0 flex justify-center w-full h-full overflow-hidden;
    @apply bg-green-50;
  }

  & :global(.video-wrapper) {
    @apply absolute object-cover w-full h-full xl:h-auto;
  }

  & :global(.overlay) {
    @apply top-0 absolute w-full h-full z-[1] bg-black-100;

    &:global(.dim-0) {
      @apply opacity-0;
    }

    &:global(.dim-10) {
      @apply opacity-10;
    }

    &:global(.dim-15) {
      @apply opacity-15;
    }

    &:global(.dim-20) {
      @apply opacity-20;
    }

    &:global(.dim-25) {
      @apply opacity-25;
    }

    &:global(.dim-30) {
      @apply opacity-30;
    }

    &:global(.dim-40) {
      @apply opacity-40;
    }

    &:global(.dim-50) {
      @apply opacity-50;
    }

    &:global(.dim-60) {
      @apply opacity-60;
    }

    &:global(.dim-70) {
      @apply opacity-70;
    }
  }

  & :global(.hero-gradient) {
    @apply top-0 absolute w-full h-full z-[2];
  }

  & :global(.has-dark-text .article-panel h3) {
    @apply text-black-100;
  }

  & :global(.media-author) {
    @apply mt-4 font-sans text-sm leading-normal;
  }

  & :global(.articles-panel) {
    @apply z-10;

    & h2 {
      @apply absolute -top-13 text-white text-3xl;
    }

    /* Add <main> background-color or manipulate with first block group-wrapper */
    &:global(.page) {
      background: linear-gradient(0, #f8faea 50%, transparent 0%);
    }

    & :global(.articles-wrapper) {
      @apply max-w-8xl mx-auto bg-white text-black-100 grid grid-cols-1 md:grid-cols-3 shadow-md;
      @apply w-[calc(100%-2rem)] md:w-[calc(100%-4rem)];
      @apply px-6 md:px-0 md:py-6 relative;

      @apply divide-y md:divide-y-0 md:divide-x divide-black-100/10;

      & :global(.hero-card) {
        @apply w-full py-6 md:py-0 md:px-6;
        &:global(> a) {
          @apply max-w-[80%];
        }
      }
    }
  }

  & :global(.text-effect) {
    &:global(.shadow-sm h1),
    &:global(.shadow-sm p) {
      text-shadow: rgba(0, 0, 0, 1) 1px 0 5px;
    }

    &:global(.shadow-lg h1),
    &:global(.shadow-lg p) {
      text-shadow: rgba(0, 0, 0, 1) 1px 0 10px;
    }

    &:global(.text-bg[data-block="core/heading"]) {
      @apply pb-2 sm:pb-0 md:pb-4;

      & h1 {
        @apply inline leading-[3.2rem] sm:leading-[3.8rem] lg:leading-[4.5rem] px-3 py-[0.01em];
        @apply box-decoration-clone;
      }
    }

    &:global(.text-bg[data-block="core/paragraph"]) {
      @apply pb-4;

      & p {
        @apply inline leading-[2.2rem] px-3 py-[0.2em];
        @apply box-decoration-clone;
      }
    }

    &:global(.white p),
    &:global(.white h1) {
      @apply bg-white;
    }

    &:global(.black p),
    &:global(.black h1) {
      @apply bg-black-100;
    }

    &:global(.shadow) {
      @apply shadow-none;
      & p,
      & h1 {
        @apply bg-black-100/60;
      }
    }

    &:global(.shadow-lg) {
      @apply shadow-none;
    }

    &:global(.cyan p),
    &:global(.cyan h1) {
      @apply bg-dark-cyan-100;
    }

    &:global(.green p),
    &:global(.green h1) {
      @apply bg-green-100;
    }

    &:global(.summer-green p),
    &:global(.summer-green h1) {
      @apply bg-summer-green-100;
    }

    &:global(.yellow p),
    &:global(.yellow h1) {
      @apply bg-campaign-yellow-100;
    }
  }
}
